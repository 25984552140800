//import { ipAddress, port, gatewayPort } from '../config/customConfig'
import axios from 'axios'
export const getHost = async () => {
    // forDeployment
    let basePath = window.location.protocol + '//' + window.location.host;
    basePath = basePath + '/secure-ui/themes/custom-config.json'
    await axios.get(basePath).then(res => {
        window.$appConfig = { ...res.data }
        return res
   })
   //forLocal
  // let config = require(`../config/custom-config.json`)
   //window.$appConfig = config
   //console.log("globalvariable", config, window.$appConfig)
   //return config
}
export const getConfig = () => {
    //console.log("config", window.$appConfig)
    const { ipAddress, port, gatewayport, lang, themePort, themeIpAddress, extAlbPort } = window.$appConfig ? window.$appConfig : require("../config/custom-config.json")
    const config = {
        ipAddress: ipAddress,
        port: port,
        gatewayport: gatewayport,
        lang: lang,
        themePort: themePort,
        themeIpAddress: themeIpAddress,
        extAlbPort: extAlbPort
    }
    return config
}

export const getAnonymousUser = (input, param) => {
    const { ipAddress, gatewayport } = getConfig()
    return axios.get(`${ipAddress}:${gatewayport}/api-gateway/anonymous/1/tracking-status?${param}=${input}`)
        .then(res => {
            return res
        }).catch(error => {
            return error.response
        });;
}
export const getThemeDetails = async () => {
    const { ipAddress, gatewayport, extAlbPort, themeIpAddress } = getConfig()
    const tenant = localStorage.getItem("tenant")
    let URL = `${themeIpAddress}:${extAlbPort}/secure-ui/themes/${tenant}/${tenant}.json`;
    return axios.get(URL, { timeout: 1000 })
        .then(res => {
            return res.data
        }).catch(res => {
            URL = `${ipAddress}:${gatewayport}/api-gateway/themes/${tenant}/${tenant}.json`;
            return axios.get(URL).then(res => {
                return res.data
            }).catch(res, URL)
        });
}


