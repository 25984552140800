import React from 'react';
import './dataTable.css';
import { Table, TableHead, TableRow, TableCell, TableBody, Collapse, makeStyles } from '@material-ui/core'
import { getStatusClass } from '../../common/utils'
import ProgressBar from "../common/ProgressBar"
import TreeTable from "../common/TreeTable"
import { FormattedMessage } from 'react-intl'


const getTableColumnsByBizType =(bizType)=>{
    const baseTableColumns = 
        [{ header: "Tracking Number", field: "jobNo" },
        { header: "Status", field: "currentStatus" },
        { header: "Carrier", field: "carrier" },
        bizType === "air" ? { header: "FltNo", field: "fltNo" }: (bizType ==="sea" ?
        { header: "Vessel / Voyage", field: "vessel / Voyage" }: (bizType ==="both" ? 
        { header: "Vessel/ Voyage/ FltNo", field: "vessel/ Voyage/ FltNo" } : {})),
        { header: "Service", field: "bizType" },
        { header: "Shipment Type", field: "loadTerm" },
        { header: "Weight", field: "weight" },
        { header: "Pieces", field: "totalPcs" },
        { header: "POR ETD", fieLd: "POR ETD" },
        { header: "POR", field: "porName" },
        { header: "POL ETD", fieLd: "polEtdDate" },
        { header: "POL", field: "polName" },
        { header: "POD ETA", fieLd: "podEtaDate" },
        { header: "POD", field: "podName" },
        { header: "Est. Delivery", field: "devryETDDate" },
        { header: "Destination", field: "estdDelDate" },
        { header: "HBL/HAWB/Direct BL No.", field: "shpNo" }]
        // { "header": "MBL/MAWB No.", "field": "devryETADate" }]
    return baseTableColumns;
}

const milstoneColoumns = [{ "header": "Date", "field": "eventDate" },
{ "header": "Time", "field": "eventTime" },
{ "header": "Location", "field": "location" },
{ "header": "Status", "field": "eventCode" },
{ "header": "Notes", "field": "type" }]

const useStyles = makeStyles({
    tableHeader: {
        fontWeight: 'bold !important',
        backgroundColor: '#FFFFFF',
        fontSize: '14px',
        borderBottom: '4px solid #CDE3E9',
        borderRight: '1px solid #DAE7EA',
        height: '54px',
        padding: '6px',
        minWidth: 100,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: 130
    },
    tabletr: {
        display: ' table !important',
        width: '100%',
        fontSize: '14px',
        tableLayout: 'fixed',
        margin: 0,
        fontFamily: "'Open Sans', Arial, sans-serif !important",
        '-webkit-font-smoothing': 'antialiased',
        '-moz-osx-font-smoothing': 'grayscale',
        '&:nth-child(even)': {
            backgroundColor: '#F3F7F9'
        }
    },
    tableCell: {
        fontSize: '14px',
        border: '1px solid #DAE7EA',
        height: '48px',
        padding: '6px',
        borderLeft: '0px',
        wordWrap: 'break-word',
        whiteSpace: 'normal',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        minWidth: 100,
        maxWidth: 300,
        width: 130
    }
})

export const TrackingTable = (props) => {
    const classes = useStyles();
    const {tableCell,tabletr,tableHeader} =classes;
    const { data, handleRowClick, handleRowClickContainer, selectedOption, tenantDetails, numFormatByLang } = props;
    const hasSeaShipment = (data || []).some((shipment) => shipment.bizType === "SE" || shipment.bizType === "SI");
    const hasAirShipment = (data || []).some((shipment) => shipment.bizType === "AE" || shipment.bizType === "AI");
    const updatedColumns = !(hasSeaShipment && hasAirShipment) ? (hasAirShipment ? getTableColumnsByBizType("air") : (hasSeaShipment ? getTableColumnsByBizType("sea") : getTableColumnsByBizType("default"))) : getTableColumnsByBizType("both");
    const renderHeader = () =>
        updatedColumns.map((column, index) => {
            return (
                <TableCell className={tableHeader} key={index} style={{ minWidth: 150, maxWidth: 300 }} title={column.header}>
                    <FormattedMessage id={column.header || 'defaultHeader'} />
                </TableCell>
            );
        })

    return (
        <div style={{ overflowX: "auto" }}>
            <Table size="small" style={{ tableLayout: "auto", overflowX: 'auto', border: '1px solid #DAE7EA', padding: '2px 10px' }}>
                <TableHead>
                    <TableRow className={tabletr}>
                        {renderHeader()}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        data ?
                            data.map((shipment, shipmentIndex) => {
                                const {bizType,jobNo,shpNo,containerNo,bookingNo,refNo,currentStatus,carrier,
                                    vesselName,vesselVoyage,fltNo,loadTerm, porEtdDate, porName,
                                    polEtdDate,polName,podEtaDate,podName,devryEtaDate,devryName, devryCity
                                }= shipment;
                                const hasSeaBiztype = bizType === 'SE' || bizType === 'SI';
                                const hasAirBizType = bizType === 'AE' || bizType === 'AI';
                                return (
                                    <React.Fragment key={shipmentIndex}>
                                        <TableRow style={{ cursor: "pointer" }} className={tabletr} onClick={() => handleRowClick(shipmentIndex)}>
                                            <TableCell className={tableCell}> <span className="hyperlink" style={{ color: tenantDetails.secondaryColor }}>
                                                {selectedOption === "JobNo" ? `JobNo, ${jobNo ? jobNo : ""}`
                                                    // : selectedOption === "MasterNo" ? `Master No. ${shipment.masterNo ? shipment.masterNo : ''}`
                                                    : selectedOption === "ShpNos" ? `Shipment No. ${shpNo ? shpNo : ''}`
                                                        : selectedOption === "BookingNos" ? `Booking No. ${bookingNo ? bookingNo : ''}`
                                                            : selectedOption === "ContainerNo" ? `Container No. ${containerNo ? containerNo : ''}`
                                                                : selectedOption === "RefNos" ? `Ref No.  ${refNo ? refNo : ''}` : ''} </span></TableCell>
                                            <TableCell className={tableCell}><span className={`${currentStatus ? 'progressStatus' + getStatusClass(currentStatus) : ''}`}></span>{currentStatus}</TableCell>
                                            <TableCell className={tableCell}> {carrier} </TableCell>
                                            <TableCell className={tableCell}> { hasSeaBiztype ? (vesselVoyage && vesselName ? `${vesselName} / ${vesselVoyage}`: (vesselVoyage || vesselName || "")) : hasAirBizType ? fltNo :""} </TableCell>
                                            <TableCell className={tableCell}> {hasSeaBiztype ? 'Sea' : hasAirBizType ? 'Air' : bizType === 'IN' ? 'Inland'  : bizType} </TableCell>
                                            <TableCell className={tableCell}> {loadTerm} </TableCell>
                                            <TableCell className={tableCell}>{
                                                // selectedOption === "MasterNo" ? '':
                                                selectedOption === "ContainerNo" ? (numFormatByLang(shipment.totWgt) ? shipment.totWgt + (shipment.totWgtUt ? ' ' + shipment.totWgtUt : '') : '')
                                                    : (shipment.totGWgt ? numFormatByLang(shipment.totGWgt) + (shipment.totGWgtUt ? ' ' + shipment.totGWgtUt : '') : '')}</TableCell>
                                            {/* <Tooltip classes={{ tooltip: classes.tooltip }} title="Times are shown in the local timezone. "> */}
                                            <TableCell className={tableCell}>{
                                                // selectedOption === "MasterNo" ? '':
                                                (shipment.totPcs ? numFormatByLang(shipment.totPcs) + (shipment.totPcsUt ? ' ' + shipment.totPcsUt : '') : '')}
                                            </TableCell>
                                            <TableCell className={tableCell}>{porEtdDate}</TableCell>
                                            <TableCell className={tableCell}>{porName}</TableCell>
                                            <TableCell className={tableCell}>{polEtdDate}</TableCell>
                                            <TableCell className={tableCell}>{polName}</TableCell>
                                            <TableCell className={tableCell}>{podEtaDate}</TableCell>
                                            <TableCell className={tableCell}>{podName}</TableCell>
                                            <TableCell className={tableCell}> {devryEtaDate}</TableCell>
                                            {/* </Tooltip> */}
                                            <TableCell className={tableCell}>{shipment.hasOwnProperty('devryName') ? devryName + ',' : ''}
                                                {shipment.hasOwnProperty('devryCity') ? devryCity : ''}</TableCell>
                                            <TableCell className={tableCell}> {shpNo ? shpNo : ''} </TableCell>
                                            {/* <TableCell className={classes.tableCell}> {shipment.masterNo} </TableCell> */}
                                        </TableRow>

                                        {/* <TableRow className={classes.tabletr} >
                                                    <TableCell className={classes.tableCell} colSpan={10}> */}
                                        <Collapse className="containerBody" in={shipment.expandedRow} timeout="auto" unmountOnExit>
                                            <ProgressBar statusClass={getStatusClass(shipment.currentStatus)} status={shipment.currentStatus ? shipment.currentStatus : null} />
                                            <TreeTable treeTableColoumns={milstoneColoumns} data={selectedOption === "ContainerNo" ? (shipment.containerStatusList ? shipment.containerStatusList : []) : (shipment.statusList ? shipment.statusList : [])} type={selectedOption === "ContainerNo" ? "containers" : ""} />

                                            {/* </TableCell>
                                                </TableRow> */}
                                            {shipment.hasOwnProperty("containers") ?
                                                <TableRow className={classes.tabletr}>
                                                    <TableCell className={classes.tableCell} colSpan={10}>
                                                        <div className="containerBody table-responsive-sm">
                                                            <h6 className={classes.tableHeader}> <FormattedMessage id="Container Number" /></h6>
                                                            <div>
                                                                {shipment.containers.map((container, index) => {
                                                                    return (<><TableRow key={index} className={classes.tabletr} style={{ cursor: "pointer" }} onClick={() => handleRowClickContainer(shipmentIndex, index)}>
                                                                        <TableCell colSpan={10} >
                                                                            <span className="hyperlink" style={{ color: tenantDetails.secondaryColor }}>{`Container No. ${container.containerNo}`}</span>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                        {container.expandedRow ?
                                                                            <Collapse in={container.expandedRow} timeout="auto" unmountOnExit>
                                                                                {/* <ProgressBar statusClass={getStatusClass(shipment.currentStatus)} status={shipment.currentStatus} /> */}
                                                                                <TreeTable treeTableColoumns={milstoneColoumns} data={container.containerStatusList ? container.containerStatusList : []} type="containers" />
                                                                            </Collapse> : null}</>)
                                                                })}

                                                            </div>
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                                :
                                                null}
                                        </Collapse>
                                    </React.Fragment>
                                )
                            }) :
                            <TableRow>
                                <TableCell className="noRecords" colSpan={10}>No Records Found</TableCell>
                            </TableRow>
                    }
                </TableBody>
            </Table >
        </div>
    );
}